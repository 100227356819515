var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "80px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "电子账薄详情" } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { border: "1px solid #EBEEF5" } },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              border: "1px solid #EBEEF5",
                              height: "200px"
                            },
                            attrs: { span: 10 }
                          },
                          [
                            _c("div", { staticClass: "area" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(" 账户余额： ")
                              ])
                            ]),
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-size": "30px",
                                    "font-family": "宋体",
                                    "margin-left": "70px",
                                    "margin-top": "20px"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.filterMoney(
                                          _vm.accountDetail.balance
                                        )
                                      ) +
                                      " 元 "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              border: "1px solid #EBEEF5",
                              height: "200px"
                            },
                            attrs: { span: 7 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "-40px" },
                                    attrs: { label: "在途余额" }
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.filterMoney(
                                            _vm.accountDetail.receivableBalance
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "-40px" },
                                    attrs: { label: "冻结余额" }
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.filterMoney(
                                            _vm.accountDetail.frozenAmount
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "-40px" },
                                    attrs: { label: "到账余额" }
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.filterMoney(
                                            _vm.accountDetail.receivedBalance
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              border: "1px solid #EBEEF5",
                              height: "200px"
                            },
                            attrs: { span: 7 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { span: 24 }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "-40px" },
                                    attrs: { label: "ID" }
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.accountDetail.userID
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: {
                                      "margin-left": "-40px",
                                      width: "360px"
                                    },
                                    attrs: { label: "账户名称" }
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.accountDetail.userName
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-left": "-40px" },
                                    attrs: { label: "账户类型" }
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.statusFormat(
                                            _vm.accountDetail.userType,
                                            "userType"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "search-inner",
                {
                  attrs: {
                    title: "",
                    "search-id": 1,
                    "search-form": _vm.searchForm
                  },
                  on: { "submit-search": _vm.search, "clear-search": _vm.reset }
                },
                [
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "时间：" } },
                          [
                            _c("ics-search-date", {
                              attrs: {
                                date: _vm.searchForm.dateArr,
                                "is-show-options": false
                              },
                              on: {
                                "update:date": function($event) {
                                  return _vm.$set(
                                    _vm.searchForm,
                                    "dateArr",
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              ),
              _c(
                "table-inner",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading.list,
                      expression: "loading.list"
                    }
                  ],
                  attrs: {
                    title: "账户明细",
                    "table-data": _vm.tableList.itemList,
                    "page-num": Number(_vm.tableList.currentPage),
                    "page-size": 10,
                    pages: Number(_vm.tableList.totalPage),
                    "page-id": _vm.tableList.pageId
                  },
                  on: { changePageNum: _vm.changePageNum }
                },
                [
                  _c(
                    "template",
                    { slot: "table-columns" },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: "center",
                          "show-overflow-tooltip": "",
                          "min-width": "100"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "txSn",
                          label: "交易流水号",
                          align: "center",
                          formatter: _vm.utils.isEffective,
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "operation",
                          label: "类型",
                          align: "center",
                          formatter: function(row, col, cell) {
                            return _vm.utils.statusFormat(
                              cell,
                              "eJz4692OperationType"
                            )
                          },
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "amount",
                          label: "金额(元)",
                          align: "center",
                          formatter: function(row, col, cell) {
                            return _vm.filterMoney(cell)
                          },
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "txTime",
                          label: "时间",
                          align: "center",
                          formatter: function(row, col, cell) {
                            return _vm.stringToDate(cell)
                          },
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark",
                          label: "备注",
                          align: "center",
                          formatter: _vm.utils.isEffective,
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }