<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form label-width="80px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="电子账薄详情">
          <el-col :span="24">
            <div style="border: 1px solid #EBEEF5;">
              <el-col :span="10" style="border: 1px solid #EBEEF5; height: 200px;">
                <div class="area">
                  <p class="title">
                    账户余额：
                  </p>
                </div>
                <el-col :span="24">
                  <p style="font-size: 30px; font-family: 宋体; margin-left: 70px;margin-top: 20px;">
                    {{ filterMoney(accountDetail.balance) }} 元
                  </p>
                </el-col>
              </el-col>
              <el-col :span="7" style="border: 1px solid #EBEEF5; height: 200px;">
                <el-col :span="24" style="margin-top: 10px;">
                  <el-form-item label="在途余额" style="margin-left: -40px;">
                    <p>{{ filterMoney(accountDetail.receivableBalance) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="冻结余额" style="margin-left: -40px;">
                    <p>{{ filterMoney(accountDetail.frozenAmount) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="到账余额" style="margin-left: -40px;">
                    <p>{{ filterMoney(accountDetail.receivedBalance) }}</p>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="7" style="border: 1px solid #EBEEF5; height: 200px;">
                <el-col :span="24" style="margin-top: 10px;">
                  <el-form-item label="ID" style="margin-left: -40px;">
                    <p>{{ utils.isEffectiveCommon(accountDetail.userID) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="账户名称" style="margin-left: -40px; width: 360px;">
                    <p>{{ utils.isEffectiveCommon(accountDetail.userName) }}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="账户类型" style="margin-left: -40px;">
                    <p>{{ utils.statusFormat(accountDetail.userType, 'userType') }}</p>
                  </el-form-item>
                </el-col>
              </el-col>
            </div>
          </el-col>
        </ics-page-inner>
        <search-inner title="" :search-id="1" :search-form="searchForm" @submit-search="search" @clear-search="reset">
          <template>
            <el-col :span="7">
              <el-form-item label="时间：">
                <ics-search-date :date.sync="searchForm.dateArr" :is-show-options="false" />
              </el-form-item>
            </el-col>
          </template>
        </search-inner>
        <table-inner v-loading="loading.list" title="账户明细" :table-data="tableList.itemList" :page-num="Number(tableList.currentPage)" :page-size="10" :pages="Number(tableList.totalPage)" :page-id="tableList.pageId" @changePageNum="changePageNum">
          <template slot="table-columns">
            <el-table-column type="index" label="序号" align="center" show-overflow-tooltip min-width="100" />
            <el-table-column prop="txSn" label="交易流水号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="operation" label="类型" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'eJz4692OperationType')" show-overflow-tooltip min-width="150" />
            <el-table-column prop="amount" label="金额(元)" align="center" :formatter="(row, col, cell) => filterMoney(cell)" show-overflow-tooltip min-width="150" />
            <el-table-column prop="txTime" label="时间" align="center" :formatter="(row, col, cell) => stringToDate(cell)" show-overflow-tooltip min-width="150" />
            <el-table-column prop="remark" label="备注" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          </template>
        </table-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsSearchDate from '@/components/search-date'
import moment from 'moment'
export default {
  components: {IcsSearchDate},
  mixins: [routeEnterMixin],
  data() {
    return {
      //userId: this.$route.query.userId,
      userId: this.$route.query.userId,
      firmId: this.$route.query.firmId,
      eaccountNumber: this.$route.query.eaccountNumber,
      accountDetail: {},
      searchForm: {
        startDate: '',
        endDate: '',
        dateArr: [],
        pageIndex: 1,
        pageSize: 10
      },
      tableList: {
        itemList: [],
        currentPage: '0',
        totalPage: '0'
      },
    }
  },
  created() {
    if (this.userId) {
      this.eJz4691()
      this.recoverSearch()
    }
  },
  methods: {
    eJz4691 () {
      this.loading.detail = true
      this.api.system.account.eJz4691(this.userId).then(result => {
        const data = JSON.parse(result.data.data)
        this.accountDetail = data || {}
      }).finally(() => {
        this.loading.detail = false
      })
    },
    recoverSearch () {
      // 用于页面初始化时拉取列表数据
      this.getSearchForm()
    },
    getSearchForm () {
      this.searchForm.pageIndex = 1
      this.searchForm.pageSize = 10
      const query = this.searchForm
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      query.startDate = this.dateFormat(start)
      query.endDate = this.dateFormat(end)
      this.eJz4692(query)
    },
    eJz4692 (searchForm) {
      searchForm.userId = this.userId
      this.loading.list = true
      if (searchForm.dateArr) {
        delete searchForm.dateArr
      }
      this.api.system.account.eJz4692(searchForm).then(result => {
        const data = JSON.parse(result.data.data)
        if (data.code === '2000') {
          this.tableList = data || {}
        } else {
          this.tableList.itemList = []
          this.tableList.currentPage = '0'
          this.tableList.totalPage = '0'
          this.tableList.pageId = 0
          this.$message.warning(data.message)
        }
      }).finally(() => {
        this.loading.list = false
      })
    },
    search () {
      if (this.searchForm.dateArr) {
        this.searchForm.startDate = this.searchForm.dateArr[0]
        this.searchForm.endDate = this.searchForm.dateArr[1]
      }
      const searchForm = {
        pageIndex: 1,
        pageSize: 10,
        startDate: this.dateFormat(this.searchForm.startDate),
        endDate: this.dateFormat(this.searchForm.endDate)
      }
      this.eJz4692(searchForm)
    },
    reset () {
      this.searchForm.dateArr = []
      this.searchForm.startDate = ''
      this.searchForm.endDate = ''
      this.searchForm.pageIndex = 1
      this.searchForm.pageSize = 10
      this.getSearchForm()
    },
    changePageNum (val) {
      this.searchForm.pageIndex = val.pageNum
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
      const searchForm = {
        pageIndex: this.searchForm.pageIndex,
        pageSize: this.searchForm.pageSize
      }
      if (this.searchForm.dateArr) {
        searchForm.startDate = this.dateFormat(this.searchForm.dateArr[0])
        searchForm.endDate = this.dateFormat(this.searchForm.dateArr[1])
      } else {
        searchForm.startDate = this.dateFormat(start)
        searchForm.endDate = this.dateFormat(end)
      }
      this.eJz4692(searchForm)
    },
    filterMoney (num) {
      return (num / 100).toFixed(2)
    },
    dateFormat (date, r) {
      return date ? moment(date).format('YYYYMMDD') : (r || '-')
    },
    stringToDate (dateStr, separator){
      let str = dateStr.slice(0, 4) + '-' + dateStr.slice(4, 6) + '-' + dateStr.slice(6, 8) + ' ' + dateStr.slice(8, 10) + ':' + dateStr.slice(10, 12) + ':' + dateStr.slice(12, 14)
      return this.utils.dateTimeFormat(str)
    }
  }
}
</script>

<style lang="less" scoped>
.area {
  overflow: hidden;
  line-height: 30px;
  height: 40px;
  padding: 0 20px;
  margin-bottom: 20px;
  .title {
    float: left;
    margin-top: 5px;
    line-height: 32px;
    font-size: 14px;
    color: #666;
  }
  .btn-inner {
    float: right;
    margin-top: 5px;
  }
}
div.radio input{margin-left:30px}
</style>
